import { QRCode } from "react-qrcode-logo";

// Common design for all QR codes across the app!
// @param props.qrValue When scanned, the value of the QR Code
const HHQRCode = (props) => {
    return (
        <>
            <QRCode
                value={props.qrValue}
                eyeRadius={[props.size / 15, props.size / 15, props.size / 15]}
                logoImage='./logo.png'
                logoPaddingStyle="circle"
                logoHeight={props.size / 3.7}
                logoWidth={props.size / 3.7}
                qrStyle='dots'
                removeQrCodeBehindLogo={false}
                size={props.size}
            />
        </>
    )
}

export default HHQRCode;
