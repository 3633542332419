import React, { useContext, useEffect, FormEvent, useState } from 'react';

import { UserContext } from '../App';

import { Form, useNavigate, useParams } from 'react-router-dom';

import { useAuthState } from 'react-firebase-hooks/auth'
import { useCollectionDataOnce, useDocumentDataOnce } from 'react-firebase-hooks/firestore';
import { collection, doc, query, where, limit, Firestore, updateDoc } from '@firebase/firestore';
import { app, auth, db } from '../firebase'

import { PhoneIcon, RepeatClockIcon } from '@chakra-ui/icons'

import {
    Box,
    Wrap,
    WrapItem,
    Text,
    Flex,
    VStack,
    HStack,
    Center,
    Input,
    InputGroup,
    InputAddon,
    InputLeftAddon,
    Grid,
    Spinner,
    Card, CardHeader, CardBody, CardFooter, Stack, Heading, Divider, ButtonGroup, Button, SimpleGrid, FormControl, Skeleton, useToast, IconButton, GridItem, CircularProgress
} from "@chakra-ui/react"

import { toNumber } from 'lodash';

// User Imports
import { HideCenterLogo } from '../App';

export const ProfilePage = () => {
    const [user, userLoading] = useAuthState(auth);
    const collectionRef = collection(db, 'users');

    const navigate = useNavigate();
    const toast = useToast();

    const { hideCenterLogoState, setHideCenterLogoState } = useContext(HideCenterLogo);
    useEffect(() => {
        // Always make the very top center logo hidden by default
        setHideCenterLogoState(false);
    }, [setHideCenterLogoState])

    // Current user info
    const userDocRef = user ? doc(collectionRef, user?.uid, 'private', 'userInfo') : undefined;
    const { currentUserData, currentUserLoading, currentUserError } = useContext(UserContext);

    // Form States
    const [userData, setUserData] = useState<any>(currentUserData);
    const [name, setName] = useState<string>("");
    const [phone, setPhone] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [age, setAge] = useState<number | null>();
    const [sex, setSex] = useState<string>("");
    const [formError, setFormError] = useState<string | null>(null);
    const [formSubmitting, setFormSubmitting] = useState<boolean>(false);

    useEffect(() => {
        // Set the state values based on the loaded document
        if (currentUserData) {
            setUserData(currentUserData);
            setName(currentUserData['name'] ?? '');
            setPhone(currentUserData['phone'] ?? '');
            setEmail(currentUserData['email'] ?? '');
            setAge(currentUserData['age'] ?? 0);
            setSex(currentUserData['sex'] ?? '');
        }
    }, [currentUserData]);

    if (currentUserError) {
        toast({
            title: "Error loading profile",
            description: currentUserError['message'],
            status: "error",
            duration: 3000,
            isClosable: true,
        });
    }

    // Form Handlers
    const handleSexSelect = (selectedGender: string): void => {
        setSex(selectedGender);
        setFormError(null);
    };

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        setFormSubmitting(true);
        event.preventDefault();

        // Validate the complicated inputs:
        let sexValid = sex === "female" || sex === "male";
        let inputsValid = sexValid;

        if (inputsValid && userDocRef) {
            try {
                if (age && age < 18) {
                    toast({
                        title: "Warning!",
                        description: "Are you really a young adult if you're not even 18 years old?",
                        status: "warning",
                        duration: 6000,
                        isClosable: true
                    });
                }

                let prevSetupComplete = currentUserData && currentUserData['setupComplete'];
                await updateDoc(userDocRef, {
                    name: name,
                    phone: phone,
                    // email: email, // email can't be changed since that's what made your account
                    age: age,
                    sex: sex,
                    setupComplete: true
                });

                toast({
                    title: "Profile updated!",
                    status: "success",
                    duration: 3000,
                    isClosable: true
                });

                // If this was the initial setup, navigate to the scan page afterwards
                if (!prevSetupComplete) {
                    navigate('/scan')
                }
            } catch (error: any) {
                toast({
                    title: "Error updating profile.",
                    description: error.message,
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            }
        }
        else {
            if (!sexValid) {
                toast({
                    title: "Error updating profile.",
                    description: "Please select your sex",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            }
        }
        setFormSubmitting(false);
    }

    const handleAgeChange = (value: string) => {
        const newAge = value.replace(/\D/g, ''); // Remove non-numeric characters

        // Apply age formatting
        setAge(toNumber(newAge.slice(0, 3)));
    }

    const handlePhoneChange = (value: string) => {
        const phoneNumber = value.replace(/\D/g, ''); // Remove non-numeric characters

        // Apply phone number formatting
        if (phoneNumber.length >= 4 && phoneNumber.length <= 6) {
            setPhone(`${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`);
        } else if (phoneNumber.length >= 7 && phoneNumber.length <= 10) {
            return setPhone(`(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6)}`);
        } else {
            return setPhone(phoneNumber);
        }
    }

    const handleReset = () => {
        setName(userData.name ?? '');
        setPhone(userData.phone ?? '');
        setEmail(userData.email ?? '');
        setAge(userData.age ?? 0);
        setSex(userData.sex ?? '');
    }

    return (
        <VStack align='center'>
            <Box maxW={'lg'} alignItems="center" justifyContent="center" fontSize="xl" >
                <Card>
                    <CardBody>
                        <VStack>
                            <Box>
                                <Heading size='lg'>
                                    <Grid
                                        templateColumns='repeat(6, 1fr)'
                                        templateRows='repeat(1, 1fr)'
                                        gap={2}
                                    >
                                        <GridItem colStart={2} colSpan={4}>{currentUserData && currentUserData['setupComplete'] ? <>Edit Profile</> : <>Create Profile</>}</GridItem>
                                        <GridItem>
                                            <IconButton
                                                aria-label="Edit"
                                                icon={<RepeatClockIcon />}
                                                onClick={handleReset} />
                                        </GridItem>
                                    </Grid>
                                </Heading>
                            </Box>
                            <Divider />
                            <form onSubmit={handleSubmit}>
                                <SimpleGrid columns={1} spacing={5}>
                                    <FormControl id="name">
                                        <Skeleton isLoaded={!currentUserLoading}>
                                            <InputGroup size='lg'>
                                                <InputLeftAddon>
                                                    <Text mr="1" color="red">*</Text>
                                                    <Text>Name</Text>
                                                </InputLeftAddon>
                                                <Input
                                                    placeholder='Name'
                                                    value={name}
                                                    onChange={(event) => setName(event.target.value)}
                                                    isRequired
                                                />
                                            </InputGroup>
                                        </Skeleton>
                                    </FormControl>
                                    <FormControl id="email">
                                        <Skeleton isLoaded={!currentUserLoading}>
                                            <InputGroup size='lg'>
                                                <InputLeftAddon>
                                                    <Text mr="1" color="red">*</Text>
                                                    <Text>Email</Text>
                                                </InputLeftAddon>
                                                <Input
                                                    placeholder='Email'
                                                    type="email"
                                                    isDisabled={true}
                                                    value={email}
                                                    onChange={(event) => setEmail(event.target.value)}
                                                    isRequired
                                                />
                                            </InputGroup>
                                        </Skeleton>
                                    </FormControl>
                                    <FormControl id="age">
                                        <Skeleton isLoaded={!currentUserLoading}>
                                            <InputGroup size='lg'>
                                                <InputLeftAddon>
                                                    <Text mr="1" color="red">*</Text>
                                                    <Text>Age</Text>
                                                </InputLeftAddon>
                                                <Input
                                                    placeholder='Age'
                                                    type="number"
                                                    min='0'
                                                    max='150'
                                                    value={age || ''}
                                                    onChange={(event) => handleAgeChange(event.target.value)}
                                                />
                                            </InputGroup>
                                        </Skeleton>
                                    </FormControl>
                                    <FormControl id="phone">
                                        <Skeleton isLoaded={!currentUserLoading}>
                                            <InputGroup size='lg'>
                                                <InputLeftAddon children={<PhoneIcon color='gray.300' />} />
                                                <Input
                                                    placeholder='(123) 456-7890'
                                                    type="tel"
                                                    value={phone}
                                                    onChange={(event) => handlePhoneChange(event.target.value)}
                                                />
                                            </InputGroup>
                                        </Skeleton>
                                    </FormControl>
                                    <FormControl id="sex">
                                        <div>
                                            <HStack spacing={4}>
                                                <Skeleton isLoaded={!currentUserLoading}>
                                                    <Button
                                                        variant={sex === "male" ? "solid" : "outline"}
                                                        colorScheme="blue"
                                                        onClick={() => handleSexSelect("male")}
                                                    >
                                                        Male
                                                    </Button>
                                                </Skeleton>
                                                <Skeleton isLoaded={!currentUserLoading}>
                                                    <Button
                                                        variant={sex === "female" ? "solid" : "outline"}
                                                        colorScheme="pink"
                                                        onClick={() => handleSexSelect("female")}
                                                    >
                                                        Female
                                                    </Button>
                                                </Skeleton>
                                            </HStack>
                                        </div>
                                    </FormControl>
                                    <Divider />
                                    <Button
                                        type="submit"
                                        variant='solid'
                                        colorScheme='green'
                                        isDisabled={!(name && email && (age && age >= 0) && sex) || formSubmitting}
                                    >
                                        {formSubmitting ? <Spinner /> : <div>Save Profile</div>}
                                    </Button>
                                </SimpleGrid>
                            </form>
                        </VStack>
                    </CardBody>
                </Card>
            </Box >
        </VStack >
    )
}