import React, { useEffect, FormEvent, useState, useContext } from 'react';

import { Form, useNavigate, useParams } from 'react-router-dom';

import { useAuthState } from 'react-firebase-hooks/auth'
import { query, getDocs, collection, where, setDoc, doc, getDoc } from "firebase/firestore";
import { logEvent } from 'firebase/analytics';
import { app, auth, db, analytics } from '../firebase'

import { PhoneIcon, RepeatClockIcon } from '@chakra-ui/icons'

import {
    Box,
    Wrap,
    WrapItem,
    Text,
    Flex,
    VStack,
    HStack,
    Center,
    Input,
    InputGroup,
    InputAddon,
    InputLeftAddon,
    Grid,
    Spinner,
    Card, CardHeader, CardBody, CardFooter, Stack, Heading, Divider, ButtonGroup, Button, SimpleGrid, FormControl, Skeleton, useToast, IconButton, GridItem, CircularProgress, FormHelperText, FormErrorMessage
} from "@chakra-ui/react"
import ReactCardFlip from 'react-card-flip';
import { createUserWithEmailAndPassword, getAuth } from 'firebase/auth';

// User Imports
import { wait } from '../util/wait';
import { HideCenterLogo } from '../App';

export const CreateAccountPage = () => {
    const MIN_PASSWORD_LENGTH = 8;

    const navigate = useNavigate();
    const toast = useToast();

    const { hideCenterLogoState, setHideCenterLogoState } = useContext(HideCenterLogo);
    useEffect(() => {
        // Always make the very top center logo hidden by default
        setHideCenterLogoState(false);
    }, [setHideCenterLogoState])

    const loading = false;

    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [isCardFlipped, setIsCardFlipped] = useState<boolean>(false);
    const [formSubmitting, setFormSubmitting] = useState<boolean>(false);

    const isValidEmail = (value: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(value);
    }

    const isValidPassword = (value: string) => {
        return value.length >= MIN_PASSWORD_LENGTH
    }

    const isEmailAndPasswordValid = () => {
        if (!isValidPassword(password)) {
            return false
        }

        if (!isValidEmail(email)) {
            return false
        }

        return true
    }

    const handleNext = (e: any) => {
        e.preventDefault();

        // Confirm that the email requirements are met
        if (!isValidEmail(email)) {
            toast({
                title: "Check Email",
                description: "Your email address is not valid!",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return
        }

        // Confirm that the password requirements are met
        if (!isValidPassword(password)) {
            toast({
                title: "Check Password",
                description: "Your password must be 8 or more characters in length!",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return
        }

        // No errors, continue
        setIsCardFlipped(true);
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setFormSubmitting(true);

        // Confirm everything is valid
        if (isEmailAndPasswordValid() && password == confirmPassword) {
            try {
                const userCredential = await createUserWithEmailAndPassword(auth, email, password)
                // Signed in
                logEvent(analytics, 'login', {
                    method: "Email and Password"
                })
                const user = userCredential.user;

                const collectionRef = collection(db, 'users');
                const userDocRef = doc(collectionRef, user?.uid, 'private', 'userInfo');
                const userDoc = await getDoc(userDocRef);
                const userDocData = userDoc.exists() ? userDoc.data() : null;
                if (!userDocData) {
                    // If a user doesn't have a userDocData, then they are likely signing in for the first time,
                    // so log a "sign_up" event
                    logEvent(analytics, 'sign_up', {
                        method: "Email and Password"
                    })
                    
                    await setDoc(userDocRef, {
                        uid: user.uid,
                        name: user.displayName,
                        authProvider: "email",
                        email: user.email,
                        setupComplete: false,
                    });

                    navigate("/profile")
                }
                else {
                    if (userDocData && !userDocData.setupComplete) {
                        navigate("/profile")
                    }
                    else {
                        navigate("/scan")
                    }
                }
            }
            catch (error: any) {
                await wait(3000);
                toast({
                    title: "Account Creation Failed",
                    description: error.code.toString().replace("auth/", '').split('-').join(' '),
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            }
        }
        setFormSubmitting(false);
    }

    return (
        <VStack align='center'>
            <Box maxW={'lg'} alignItems="center" justifyContent="center" fontSize="xl" >
                <ReactCardFlip isFlipped={isCardFlipped} flipDirection='horizontal'>
                    <Card>
                        <CardBody>
                            <VStack>
                                <Box>
                                    <Heading size='lg'>
                                        <Grid
                                            templateColumns='repeat(6, 1fr)'
                                            templateRows='repeat(1, 1fr)'
                                            gap={2}
                                        >
                                            <GridItem colStart={2} colSpan={4}>Create Account</GridItem>
                                        </Grid>
                                    </Heading>
                                </Box>
                                <Divider />
                                <form onSubmit={handleNext}>
                                    <SimpleGrid columns={1} spacing={5}>
                                        <FormControl id="email" isInvalid={!isValidEmail(email)}>
                                            <Skeleton isLoaded={!loading}>
                                                <InputGroup size='lg'>
                                                    <InputLeftAddon>
                                                        <Text>Email</Text>
                                                    </InputLeftAddon>
                                                    <Input
                                                        type="email"
                                                        placeholder='your@email.com'
                                                        value={email}
                                                        onChange={(event) => setEmail(event.target.value)}
                                                        isRequired
                                                    />
                                                </InputGroup>
                                            </Skeleton>
                                        </FormControl>
                                        <FormControl id="password" isInvalid={!isValidPassword(password)}>
                                            <Skeleton isLoaded={!loading}>
                                                <InputGroup size='lg'>
                                                    <InputLeftAddon>
                                                        <Text>Password</Text>
                                                    </InputLeftAddon>
                                                    <Input
                                                        type="password"
                                                        placeholder=''
                                                        value={password}
                                                        onChange={(event) => setPassword(event.target.value)}
                                                        isRequired
                                                    />
                                                </InputGroup>
                                                <FormHelperText>Password must be at least 8 characters in length</FormHelperText>
                                            </Skeleton>
                                        </FormControl>
                                        <Divider />
                                        <Button
                                            type='submit'
                                            variant='solid'
                                            colorScheme='green'
                                            isDisabled={!isEmailAndPasswordValid()}
                                        >
                                            <div>Next</div>
                                        </Button>
                                    </SimpleGrid>
                                </form>
                            </VStack>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody>
                            <VStack>
                                <Box>
                                    <Heading size='lg'>
                                        <Grid
                                            templateColumns='repeat(6, 1fr)'
                                            templateRows='repeat(1, 1fr)'
                                            gap={2}
                                        >
                                            <GridItem colStart={2} colSpan={4}>Confirm Password</GridItem>
                                        </Grid>
                                    </Heading>
                                </Box>
                                <Divider />
                                <form onSubmit={handleSubmit}>
                                    <SimpleGrid columns={1} spacing={5}>
                                        <FormControl id="confirmPassword" isInvalid={password != confirmPassword}>
                                            <Skeleton isLoaded={!loading}>
                                                <InputGroup size='lg'>
                                                    <InputLeftAddon>
                                                        <Text>Password</Text>
                                                    </InputLeftAddon>
                                                    <Input
                                                        type="password"
                                                        placeholder=''
                                                        value={confirmPassword}
                                                        onChange={(event) => setConfirmPassword(event.target.value)}
                                                        isRequired
                                                    />
                                                </InputGroup>
                                                <FormHelperText>Passwords must match</FormHelperText>
                                            </Skeleton>
                                        </FormControl>
                                        <Divider />
                                        <SimpleGrid columns={2} spacing={10}>
                                            <Flex>
                                                <Button
                                                    width={'100%'}
                                                    onClick={(event) => setIsCardFlipped(false)}
                                                    isLoading={formSubmitting}
                                                >
                                                    Back
                                                </Button>
                                            </Flex>
                                            <Flex>
                                                <Button
                                                    width={'100%'}
                                                    type='submit'
                                                    variant='solid'
                                                    colorScheme='green'
                                                    isDisabled={password != confirmPassword}
                                                    isLoading={formSubmitting}
                                                >
                                                    Create Account
                                                </Button>
                                            </Flex>
                                        </SimpleGrid>
                                    </SimpleGrid>
                                </form>
                            </VStack>
                        </CardBody>
                    </Card>
                </ReactCardFlip>
            </Box >
        </VStack >
    )
}