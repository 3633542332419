import React, { useEffect, FormEvent, useState, useContext } from 'react';

import { Form, useNavigate, useParams } from 'react-router-dom';

import { useAuthState } from 'react-firebase-hooks/auth'
import { app, auth, db } from '../firebase'
import { collection, doc, setDoc, serverTimestamp, Timestamp, query, orderBy, limit } from 'firebase/firestore';

import { PhoneIcon, RepeatClockIcon } from '@chakra-ui/icons'

import AdminPanelCard from "../components/AdminPanelCard";

import {
    Box,
    Heading,
    Wrap,
    Text,
    VStack,
    Input,
    Spinner,
    Button,
    useToast,
} from "@chakra-ui/react"

// Contexts
import { HideCenterLogo, MainLogoURLContext } from '../App';

import { convertUTCToLocal, localToUTCDatetime } from '../util/convertUTCToLocal';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import MainLogoUploader from '../components/MainLogoUploader';
import HHQRCode from '../components/HHQRCode';
import { TEST_QR_CODE_MAGIC_TEXT } from '../util/tutorial';

export const AdminPage = () => {
    const [user, userLoading] = useAuthState(auth);

    const navigate = useNavigate();
    const toast = useToast();

    const { hideCenterLogoState, setHideCenterLogoState } = useContext(HideCenterLogo);
    useEffect(() => {
        // Always make the very top center logo hidden by default
        setHideCenterLogoState(false);
    }, [setHideCenterLogoState])

    const [scheduledTimestamp, setScheduledTimestamp] = useState<Date | undefined>(new Date());

    const matchGroupCollection = collection(db, 'matches');

    // Get all the match generation runs that haven't run yet and retrieve the next one up
    const matchGroupQuery = query(matchGroupCollection, orderBy("matchesScheduledFor", 'asc'), limit(1));
    const [matchGroups, matchGroupsLoading, matchGroupError] = useCollectionData(matchGroupQuery);
    const [nextScheduledMatch, setNextScheduledMatch] = useState<any>();
    const [isUsingEmulator, setIsUsingEmulator] = useState<boolean>(false);
    useEffect(() => {
        if (matchGroups && matchGroups?.length > 0) {
            setNextScheduledMatch(matchGroups.at(0));
        }
        else {
            setNextScheduledMatch(null);
        }

        // Check if we're running on the local emulator
        if (window.location.hostname.includes("localhost")) {
            setIsUsingEmulator(true);
        }
        else {
            setIsUsingEmulator(false);
        }
    }, [matchGroups]);

    // Call the generateMatchesOnCall cloud function
    const generateMatchesNow = async () => {
        const functions = getFunctions();
        const generateMatchesOnCall = httpsCallable(functions, 'generateMatchesOnCall');
        setIsGeneratingMatches(true);

        generateMatchesOnCall({})
            .then(() => {
                setNextScheduledMatch(null);
            })
            .finally(() => {
                setIsGeneratingMatches(false);
            });
    }

    const [isGeneratingMatches, setIsGeneratingMatches] = useState(false);
    const handleGenerateMatches = async () => {
        // Check if we're using the emulator first
        if (isUsingEmulator) {
            generateMatchesNow();
            return;
        }

        if (!scheduledTimestamp) {
            toast({
                title: "Set the Time!",
                description: "You must select a time for the matches to be generated!",
                status: "success",
                duration: 6000,
                isClosable: true
            });
            console.error("You must select a time for the matches to be generated!");
            return
        }

        setIsGeneratingMatches(true);

        try {
            // Call the function to schedule the matches
            const functions = getFunctions();
            const enqueueGenerateMatchesOnCall = httpsCallable(functions, 'enqueueGenerateMatchesOnCall');
            await enqueueGenerateMatchesOnCall({ matchesScheduledFor: scheduledTimestamp })

            // Read the result of the cloud function
            toast({
                title: "Match Generation Scheduled!",
                description: "Users will be able to view their matches at " + scheduledTimestamp,
                status: "success",
                duration: 6000,
                isClosable: true
            });
        } catch (error: any) {
            toast({
                title: "Something went wrong!",
                description: error.message,
                status: "error",
                duration: 6000,
                isClosable: true
            });
            console.error(error);
        }

        setIsGeneratingMatches(false);
    }

    const handleCancelMatchGeneration = () => {
        setIsGeneratingMatches(true);
        const functions = getFunctions();
        const cancelGenerateMatches = httpsCallable(functions, 'cancelGenerateMatches');

        cancelGenerateMatches({ id: nextScheduledMatch.id })
            .then(() => {
                setNextScheduledMatch(null);
                setIsGeneratingMatches(false);
            });
    }

    const handleDateTimeChange = (datetime_str: string) => {
        setScheduledTimestamp(localToUTCDatetime(datetime_str));
    }

    return (
        <>
            <Box>
                <Heading size='lg'>
                    Admin Panel
                </Heading>
            </Box>
            <Wrap direction={['column', 'row']} spacing='24px' justify={'center'} marginTop={'12px'}>
                <AdminPanelCard title="Manage Match Generation">
                    <VStack>
                        {isGeneratingMatches || matchGroupsLoading ? <Spinner />
                            :
                            <>
                                <Box hidden={!nextScheduledMatch}>
                                    <Text>Next Match Generation:</Text>
                                    <Text>{nextScheduledMatch?.matchesScheduledFor.toDate().toLocaleString()}</Text>
                                </Box>
                                <Input
                                    type="datetime-local"
                                    value={convertUTCToLocal(scheduledTimestamp)}
                                    onChange={(event) => handleDateTimeChange(event.target.value)}
                                    hidden={matchGroups && matchGroups?.length > 0}
                                    isDisabled={isUsingEmulator}
                                />
                                <div hidden={!isUsingEmulator}>
                                    <Text color="red">
                                        TaskQueue is not supported on local emulator.
                                        Click "Generate Matches" to generate matches immediately.
                                    </Text>
                                    <br />
                                    <Text color="red">
                                        Interactions from the past 30 days will be processed.
                                    </Text>
                                </div>
                                <Button hidden={nextScheduledMatch} onClick={handleGenerateMatches} isDisabled={!scheduledTimestamp}>Generate Matches</Button>
                                <Button hidden={!nextScheduledMatch} onClick={handleCancelMatchGeneration} colorScheme={'red'}>Cancel Match Generation</Button>
                            </>}
                    </VStack>
                </AdminPanelCard>

                <AdminPanelCard title="Upload New Logo">
                    <MainLogoUploader />
                </AdminPanelCard>

                <AdminPanelCard title="Test QR Code">
                    <VStack>
                        <HHQRCode qrValue={TEST_QR_CODE_MAGIC_TEXT} size={250}/>
                    </VStack>
                </AdminPanelCard>
            </Wrap >
        </>
    )
}