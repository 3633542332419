import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Card, CardBody, CardHeader, Divider, Flex, Grid, GridItem, Heading, IconButton, Text, useClipboard } from "@chakra-ui/react";

import { EmailIcon, PhoneIcon } from '@chakra-ui/icons'

import { logEvent } from 'firebase/analytics';
import { analytics } from '../firebase'

import { useState } from "react";

interface MatchCardProps {
    // uid of the match
    uid: string;

    // Name of this match
    name: string;

    // This match's email
    email: string

    // This match's phone
    phone?: string

    // In the list of total matches, what index does this match appear?
    // Used to track whether or not users only contact the first match in their matches list
    listIndex?: number
}

// Common design for all the cards on the Admin Panel page
// @param title What to display as the title of this card
// @param children What to display in the card
const MatchCard = ({ uid, name, email, phone, listIndex }: MatchCardProps) => {

    // Google Analytics Events
    const MATCH_CONTACT_INFO_COPIED = 'match_contact_info_copied';
    const MATCH_CONTACT_INFO_FIRST_COPY = 'match_contact_info_first_copy';
    const MATCH_CONTACT_INFO_OPENED = 'match_contact_info_opened';
    const MATCH_CONTACT_INFO_FIRST_OPEN = 'match_contact_info_first_open';

    /// Number that represents the accordion object
    const AccordionIndex_e = Object.freeze({
        Closed: Number(-1),
        Phone: Number(0),
        Email: Number(1),
    })

    /// String that represents the accordion object
    const AccordionName_e = Object.freeze({
        Closed: "",
        Phone: "phone",
        Email: "email",
    })

    const {
        onCopy: onPhoneCopy,
        value: phoneValue,
        setValue: setPhoneValue,
        hasCopied: hasPhoneCopied
    } = useClipboard(phone ? phone : "");
    const [phoneCopiedOnce, setPhoneCopiedOnce] = useState<boolean>(false);
    /// @brief Copy the text of the phone and log the analytics events
    const handlePhoneCopy = () => {
        onPhoneCopy();

        logEvent(analytics, MATCH_CONTACT_INFO_COPIED, {
            type: AccordionName_e.Phone,
            otherUID: uid,
            listIndex: listIndex
        });

        if (!phoneCopiedOnce) {
            logEvent(analytics, MATCH_CONTACT_INFO_FIRST_COPY, {
                type: AccordionName_e.Phone,
                otherUID: uid,
                listIndex: listIndex
            });
            setPhoneCopiedOnce(true);
        }
    }

    const {
        onCopy: onEmailCopy,
        value: emailValue,
        setValue: setEmailValue,
        hasCopied: hasEmailCopied
    } = useClipboard(email ? email : "");
    const [emailCopiedOnce, setEmailCopiedOnce] = useState<boolean>(false);
    /// @brief Copy the text of the email and log the analytics events
    const handleEmailCopy = () => {
        onEmailCopy();

        logEvent(analytics, MATCH_CONTACT_INFO_COPIED, {
            type: AccordionName_e.Email,
            otherUID: uid,
            listIndex: listIndex
        });

        if (!emailCopiedOnce) {
            logEvent(analytics, MATCH_CONTACT_INFO_FIRST_COPY, {
                type: AccordionName_e.Email,
                otherUID: uid,
                listIndex: listIndex
            });
            setEmailCopiedOnce(true);
        }
    }

    const [phoneRevealedOnce, setPhoneRevealedOnce] = useState<boolean>(false);
    const [emailRevealedOnce, setEmailRevealedOnce] = useState<boolean>(false);
    /// @brief Handle analytics events based on the accordion state changes
    /// @param expandedIndex The index of the accordion item that changed
    const handleOnAccordionChange = (expandedIndex: number) => {
        switch (expandedIndex) {
            case AccordionIndex_e.Phone:
                logEvent(analytics, MATCH_CONTACT_INFO_OPENED, {
                    type: AccordionName_e.Phone,
                    otherUID: uid,
                    listIndex: listIndex
                });

                if (!phoneRevealedOnce) {
                    logEvent(analytics, MATCH_CONTACT_INFO_FIRST_OPEN, {
                        type: AccordionName_e.Phone,
                        otherUID: uid,
                        listIndex: listIndex
                    });
                    setPhoneRevealedOnce(true);
                }

                break;
            case AccordionIndex_e.Email:
                logEvent(analytics, MATCH_CONTACT_INFO_OPENED, {
                    type: AccordionName_e.Email,
                    otherUID: uid,
                    listIndex: listIndex
                });

                if (!emailRevealedOnce) {
                    logEvent(analytics, MATCH_CONTACT_INFO_FIRST_OPEN, {
                        type: AccordionName_e.Email,
                        otherUID: uid,
                        listIndex: listIndex
                    });
                    setEmailRevealedOnce(true);
                }

                break;
            default:
                break;
        }
    }

    return (
        <Card maxW={'lg'}>
            <CardHeader>
                <Heading size='md'>{name}</Heading>
            </CardHeader>
            <CardBody padding={0}>
                <Accordion allowToggle onChange={handleOnAccordionChange}>
                    <AccordionItem hidden={!phone}>
                        <h2>
                            <AccordionButton>
                                <Box as="span" textAlign='left' flex={'1'}>
                                    <PhoneIcon /> Phone
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel>
                            <Flex justifyContent={'center'}>
                                <Button variant='ghost' size={'md'} flexGrow={3} onClick={handlePhoneCopy}>
                                    {hasPhoneCopied ? "Phone Copied!" : phone}
                                </Button>
                            </Flex>
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem borderBottom={'hidden'}>
                        <h2>
                            <AccordionButton>
                                <Box as="span" flex={'auto'} textAlign='left' flexWrap={'nowrap'}>
                                    <EmailIcon /> Email
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel>
                            <Flex justifyContent={'center'}>
                                <Button variant='ghost' size={'md'} flexGrow={3} onClick={handleEmailCopy}>
                                    {hasEmailCopied ? "Email Copied!" : email}
                                </Button>
                            </Flex>
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>
            </CardBody>
        </Card >
    )
}

export default MatchCard;
