import { useState } from 'react';
import { Button, HStack, useToast, Text } from "@chakra-ui/react";

import {
    FileUpload,
    FileUploadDropzone,
    FileUploadPreview,
    FileUploadTrigger,
} from '@saas-ui/file-upload'

import {
    FileChangeDetails,
    FileRejectDetails
} from '@zag-js/file-upload'

import { ref, uploadBytes} from "firebase/storage"
import { storage } from '../firebase'

import MainLogo from "./MainLogo";

// Implements a drag and drop file uploader for the main logo, used on the AdminPage
const MainLogoUploader = () => {
    const ERROR_TIMEOUT_MS = 5000;
    const toast = useToast();

    const [fileToUpload, setFileToUpload] = useState<File | undefined>(undefined);

    const handleFileChanges = (details: FileChangeDetails) => {
        setFileToUpload(details?.acceptedFiles[0]);
    }

    const handleFileReject = (details: FileRejectDetails) => {
        toast({
            title: "Invalid File",
            description: details?.files[0]?.errors[0],
            status: "error",
            duration: ERROR_TIMEOUT_MS,
            isClosable: true
        });
    }

    const handleSubmit = () => {
        const SUCCESS_TIMEOUT_MS = ERROR_TIMEOUT_MS;

        const mainLogoRef = ref(storage, "images/logo.png");

        fileToUpload?.arrayBuffer().then((data) => {
            // Create file metadata including the content type
            const metadata = {
                contentType: 'image/png',
            };

            uploadBytes(mainLogoRef, data, metadata).then((snapshot) => {
                toast({
                    title: "Upload Successful",
                    description: "Refresh the page to see the new logo!",
                    status: "success",
                    duration: SUCCESS_TIMEOUT_MS,
                    isClosable: true
                });
            })
            .catch((error) => {
                toast({
                    title: "Upload Failed",
                    description: error.code.toString(),
                    status: "error",
                    duration: ERROR_TIMEOUT_MS,
                    isClosable: true
                });
            });
        })
    }

    return (
        <FileUpload
            maxFileSize={1024 * 1024} // just over 1MB
            maxFiles={1}
            accept="image/png"
            onFilesChange={handleFileChanges}
            // onFileReject={handleFileReject} // TODO: Some reason, this prop is ignored, so the handle won't fire for some reason
        >
            {({ files, deleteFile }) => (
                <FileUploadDropzone>
                    <Text fontSize="sm">Drag your image here (*.png)</Text>
                    {!files?.length ? (
                        <>
                            <MainLogo enableAnimation={false} />
                            <FileUploadTrigger as={Button}>Select files</FileUploadTrigger>
                        </>
                    ) : (
                        <>
                            <FileUploadPreview file={files[0]} maxW={'150px'} />
                            <HStack>
                                <Text fontSize="sm">{files[0].name}</Text>
                                <Button
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        deleteFile(files[0])
                                    }}
                                >
                                    Clear
                                </Button>
                            </HStack>
                            <Button
                                type="submit"
                                variant='solid'
                                colorScheme='green'
                                onClick={handleSubmit}
                            >
                                Publish New Logo
                            </Button>
                        </>
                    )}
                </FileUploadDropzone>
            )}
        </FileUpload>
    )
}

export default MainLogoUploader;


