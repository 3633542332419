
// Return a promise that resolves after a set number of milliseconds have passed
//
// @param timeout_ms How long before promise resolves in milliseconds
// @return promise which resolves after timeout_ms milliseconds
export function wait(timeout_ms: number) {
    return new Promise(resolve => {
        setTimeout(resolve, timeout_ms);
    });
}
