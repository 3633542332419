import React, { useEffect, FormEvent, useState, useContext } from 'react';

import { Form, useNavigate, useParams } from 'react-router-dom';

import { useAuthState } from 'react-firebase-hooks/auth'
import { app, auth, db } from '../firebase'
import { getFunctions, httpsCallable } from "firebase/functions"

import { PhoneIcon, EmailIcon, RepeatClockIcon } from '@chakra-ui/icons'

import {
    Box,
    Wrap,
    WrapItem,
    Text,
    Flex,
    VStack,
    HStack,
    Center,
    Input,
    InputGroup,
    InputAddon,
    InputLeftAddon,
    Grid,
    Spinner,
    Card, CardHeader, CardBody, CardFooter, Stack, Heading, Divider, ButtonGroup, Button, SimpleGrid, FormControl, Skeleton, useToast, IconButton, GridItem, CircularProgress
} from "@chakra-ui/react"
import { useCollectionDataOnce, useDocumentDataOnce } from 'react-firebase-hooks/firestore';
import { collection, doc, query, where, or, orderBy, limit, getDocs, Timestamp } from '@firebase/firestore';

// User Imports
import { HideCenterLogo } from '../App';
import MatchCard from '../components/MatchCard';

export const MatchesPage = () => {
    const [user, userLoading] = useAuthState(auth);

    const navigate = useNavigate();
    const toast = useToast();

    const { hideCenterLogoState, setHideCenterLogoState } = useContext(HideCenterLogo);
    useEffect(() => {
        // Always make the very top center logo hidden by default
        setHideCenterLogoState(false);
    }, [setHideCenterLogoState])

    const matchesCollection = collection(db, 'matches');

    const [matchesQuery, setMatchesQuery] = useState<any>(null);
    const [matchDocuments, matchesLoading, matchesError] = useCollectionDataOnce(matchesQuery);
    const [matches, setMatches] = useState<any[]>([]);

    // Flag to indicate whether matches have been scheduled for a particular time
    const [matchGenerationPending, setMatchGenerationPending] = useState<boolean>(false);
    const [matchesScheduledFor, setMatchesScheduledFor] = useState<Date>(new Date());

    useEffect(() => {
        const latestMatchesQuery = query(matchesCollection, orderBy("timestamp", 'desc'), limit(1));
        if (user) {
            getDocs(latestMatchesQuery).then((snapshot) => {
                const latestMatchesDoc = snapshot.docs.at(0);
                // Set the matches query based on user and the latest matchesList to use
                if (latestMatchesDoc) {
                    // If the matchesScheduledFor field is present, then set the flag and trigger the text message
                    // to be displayed saying when matches will be generated!
                    if (latestMatchesDoc.data().matchesScheduledFor) {
                        setMatchGenerationPending(true);
                        setMatchesScheduledFor(latestMatchesDoc.data().matchesScheduledFor.toDate());
                    }
                    else {
                        setMatchGenerationPending(false);
                    }

                    setMatchesQuery(query(collection(db, 'matches', latestMatchesDoc?.data().id, 'matchesList'), or(where("aID", "==", user.uid), where("bID", "==", user.uid))))
                }
            });
        }
    }, [user]);

    useEffect(() => {
        // If there are match documents, process them so only the "other" user's info is displayed
        if (matchDocuments && user) {
            let matchList: any[] = [];
            matchDocuments.forEach((document) => {
                // Find which user in the match is the current user
                if (document.aID === user.uid) {
                    // Include the information for the 'other' user in the matchList
                    matchList.push({
                        'otherUID': document.bID,
                        'otherName': document.bName,
                        'otherEmail': document.bEmail,
                        'otherPhone': document.bPhone,
                        'id': document.id,
                        // 'timestamp': document.timestamp
                    });
                }
                else if (document.bID === user.uid) {
                    // Include the information for the 'other' user in the matchList
                    matchList.push({
                        'otherUID': document.aID,
                        'otherName': document.aName,
                        'otherEmail': document.aEmail,
                        'otherPhone': document.aPhone,
                        'id': document.id,
                        // 'timestamp': document.timestamp
                    });
                }
                else {
                    // Do nothing
                }
            })

            setMatches(matchList);
        }
    }, [matchDocuments, user])

    return (
        <>
            <Box>
                <Heading size='lg'>
                    My Matches
                </Heading>
            </Box>
            <Wrap direction={['column', 'row']} spacing='24px' justify={'center'} marginTop={'12px'}>
                {(matchesLoading || !matchesQuery) ? <Spinner size={'lg'} /> :
                    matches?.map((match, matchIndex) => (
                        <MatchCard uid={match.otherUID} name={match.otherName} email={match.otherEmail} phone={match.otherPhone} listIndex={matchIndex} key={match.id} />
                    ))
                }
                {!(matchesLoading || !matchesQuery) && matches.length === 0 ? (
                    <>
                        {matchGenerationPending ? (
                            <VStack maxW={"480px"} alignContent={'center'}>
                                <Text >Matches will be revealed at </Text>
                                <Text as='b'>{matchesScheduledFor.toLocaleDateString()} at {matchesScheduledFor.toLocaleTimeString()}</Text>
                            </VStack>
                        ) : (
                            <>
                                <Text maxW={"480px"} align={'center'}>
                                    Unfortunately, it looks like you didn't match with anyone.
                                    However, we hope you've enjoyed your experience and will come to
                                    our future events! (Provided you're still single, of course)
                                </Text>
                            </>
                        )}
                    </>
                ) : (
                    <></>
                )}
            </Wrap >
        </>
    )
}