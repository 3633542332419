import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import {
  ChakraProvider,
  Box,
  Text,
  VStack,
  Image,
  Code,
  Grid,
  theme,
  Button,
  ButtonGroup,
  Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, useDisclosure,
  IconButton,
  GridItem,
  HStack,
  Flex,
  Spacer,
  propNames,
  DrawerFooter,
  Spinner,
  useToast,
} from "@chakra-ui/react"
import { HamburgerIcon } from '@chakra-ui/icons'

import { ColorModeSwitcher } from "./ColorModeSwitcher"

import { connectFirestoreEmulator, getFirestore, query, getDocs, collection, where, addDoc, doc } from "firebase/firestore";
import 'firebase/auth';
import { connectStorageEmulator, ref, getDownloadURL } from "firebase/storage"

import { connectFunctionsEmulator, getFunctions } from "firebase/functions"

import { setUserId } from 'firebase/analytics';

import { useAuthState } from 'react-firebase-hooks/auth';
import { useDocumentData, useCollectionData } from 'react-firebase-hooks/firestore';
import { connectAuthEmulator } from "firebase/auth"
import { GoogleAuthProvider } from "firebase/auth"
import { app, db, auth, analytics, storage} from "./firebase"

// Components
import HHQRCode from "./components/HHQRCode"
import MainLogo from "./components/MainLogo"

// Pages
import { SignInPage } from "./pages/SignInPage";
import { ScannerPage } from "./pages/ScannerPage";
import { ProfilePage } from "./pages/ProfilePage";
import { AdminPage } from "./pages/AdminPage";
import { MatchesPage } from "./pages/MatchesPage";
import { CreateAccountPage } from "./pages/CreateAccountPage"

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link as RouteLink,
  Navigate,
  useNavigate,
} from "react-router-dom";

if (window.location.hostname.includes("localhost")) {
  connectAuthEmulator(auth, 'http://localhost:9099');
  connectFirestoreEmulator(db, 'localhost', 8080);
  connectFunctionsEmulator(getFunctions(), 'localhost', 5001)
  connectStorageEmulator(storage, 'localhost', 9199)
}

const SignOut = (props: any) => {

  const handleSignOut = () => {
    props.onClick();
    auth.signOut();
  }

  return auth.currentUser && (
    <Button w="100%" variant="ghost" onClick={handleSignOut}>Sign Out</Button>
  )
}

const SignIn = (props: any) => {
  const navigate = useNavigate();

  const handleSignIn = () => {
    props.onClick();
    navigate("/signIn");
  }

  return !auth.currentUser ? <Button w="100%" variant="ghost" onClick={handleSignIn}> Sign In</Button > : null
}

export const UserContext = React.createContext<any>(null);
export const UserQrCodeContext = React.createContext<any>(null);

// Allows pages to decide if the center logo at the top of the screen should be displayed
export const HideCenterLogo = React.createContext<any>({hideCenterLogoState: false, setHideCenterLogoState: () => {}});

// Allows the root page to load the Logo URL once and pass it along to other components
export const MainLogoURLContext = React.createContext<any>({ mainLogoURL: undefined})

export const App = () => {
  const [user, authenticationLoading] = useAuthState(auth);

  const toast = useToast();

  // Current user info
  const usersCollectionRef = collection(db, 'users');
  const currentUserDocRef = user ? doc(usersCollectionRef, user?.uid, 'private', 'userInfo') : undefined;
  const [currentUserValue, currentUserLoading, currentUserError, snapshot] = useDocumentData(currentUserDocRef);
  const [currentUserData, setCurrentUserData] = useState<any>(currentUserValue);

  // HideCenterLogo context
  const [hideCenterLogoState, setHideCenterLogoState] = useState<boolean | undefined>(true);
  const hideCenterLogoContext = { hideCenterLogoState, setHideCenterLogoState }

  // MainLogoURLContext
  const [mainLogoURL, setMainLogoURL] = useState<string | undefined>(undefined);
  const mainLogoURLContext = { mainLogoURL };
  useEffect(() => {
    // How long to display the error message when the logo fails to load
    const ERROR_TIMEOUT_MS = 5000;

    const mainLogoRef = ref(storage, 'images/logo.png');
    getDownloadURL(mainLogoRef).then((url: string) => {
      setMainLogoURL(url);
    })
      .catch((error) => {
        toast({
          title: "Failed to Load Logo",
          description: error.code.toString(),
          status: "error",
          duration: ERROR_TIMEOUT_MS,
          isClosable: true
        });
      })
  }, [setMainLogoURL, toast])

  const QR_CODE_PREFIX = window.location.origin + "/";
  const currentUserQrCode = QR_CODE_PREFIX + (user?.uid ?? '');

  useEffect(() => {
    // Set the user data values based on the loaded document
    if (currentUserValue) {
      setCurrentUserData(currentUserValue);
    }
  }, [currentUserValue]);

  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  useEffect(() => {
    return auth.onAuthStateChanged(async (user) => {
      if (user) {
        // Handle analytics
        setUserId(analytics, user.uid);

        try {
          const idTokenResult = await user.getIdTokenResult();
          setIsAdmin(idTokenResult.claims.admin === true);
        } catch (e: any) {
          setIsAdmin(false);
        }
      }
      else {
        // Handle analytics
        setUserId(analytics, null);

        setIsAdmin(false);
      }
    })

  }, [db, user]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleSignIn = () => {
    onClose();
  }

  const handleSignOut = () => {
    onClose();
  }

  return <ChakraProvider theme={theme}>
    <UserContext.Provider value={{ currentUserData, currentUserLoading, currentUserError }}>
      <UserQrCodeContext.Provider value={{ currentUserQrCode, QR_CODE_PREFIX }}>
        <HideCenterLogo.Provider value={hideCenterLogoContext}>
          <MainLogoURLContext.Provider value={mainLogoURLContext}>
            <Router>
              <Drawer isOpen={isOpen} onClose={onClose} placement="left" size="xs">
                <DrawerOverlay />
                <DrawerContent>
                  <DrawerCloseButton />
                  <DrawerHeader>Menu</DrawerHeader>
                  <DrawerBody>
                    <Box as="nav">
                      <VStack justifyContent={"right"}>
                        <SignIn onClick={handleSignIn} />
                        {user ?
                          <>
                            <Button as={Link} to="/scan" w="100%" variant="ghost" onClick={onClose}> Scan</Button >
                            <Button as={Link} to="/matches" w="100%" variant="ghost" onClick={onClose}> Matches</Button >
                            <Button as={Link} to="/profile" w="100%" variant="ghost" onClick={onClose}> Profile</Button >
                          </>
                          : null
                        }
                        {isAdmin ?
                          <>
                            <Button as={Link} to="/admin" w="100%" variant="ghost" onClick={onClose}> Admin Dashboard</Button >
                          </>
                          : null
                        }
                        <SignOut onClick={handleSignOut} />
                      </VStack>
                    </Box>
                  </DrawerBody>
                  <DrawerFooter justifyContent={"center"}>
                    {user ?
                      <>
                        <VStack>
                          <Text>Your QR Code</Text>
                          <Box backgroundColor={'white'}>
                            <HHQRCode qrValue={currentUserQrCode} size={250}/>
                          </Box>
                        </VStack>
                      </>
                      : null
                    }
                  </DrawerFooter>
                </DrawerContent>
              </Drawer>
              <Box textAlign="center" fontSize="xl">
                <Grid minH="100vh" p={3}>
                  <Flex>
                    <IconButton
                      aria-label="Menu"
                      variant='ghost'
                      icon={<HamburgerIcon />}
                      onClick={onOpen}
                    />
                    <Spacer />
                    <Box minH="0" paddingTop={"1.5"} justifyContent={'center'} hidden={hideCenterLogoState}>
                      <MainLogo maxH={'40px'}/>
                    </Box>
                    <Spacer />
                    <ColorModeSwitcher justifySelf="flex-end" />
                  </Flex>
                  <Routes>
                    <Route path="/" element={<Navigate to="/signIn" replace />} />
                    <Route path="/signIn" element={<SignInPage />} />
                    <Route path="/createAccount" element={<CreateAccountPage />} />
                    {user ?
                      <>
                        <Route path="/scan" element={<ScannerPage />} />
                        <Route path="/matches" element={<MatchesPage />} />
                        <Route path="/profile" element={<ProfilePage />} />
                        {isAdmin ?
                          <>
                            <Route path="/admin" element={<AdminPage />} />
                          </>
                          :
                          <>
                          </>
                        }
                      </>
                      :
                      <></>
                    }
                    {
                      // Check if the user's authentication is loaded or not
                      (authenticationLoading) ?
                        <>
                          <Route path="*" element={<Box textAlign="center" fontSize="xl"><Spinner size={'lg'} /></Box>} />
                        </>
                        :
                        <>
                          <Route path="*" element={<Navigate to="/" replace />} />
                        </>
                    }
                  </Routes>
                  <Box as="footer" alignContent={"center"}>
                    <Text>Powered by </Text>
                    <Text>Hot & Holy Catholic Young Adult Singles</Text>
                  </Box>
                </Grid>
              </Box >
            </Router >
          </MainLogoURLContext.Provider>
        </HideCenterLogo.Provider>
      </UserQrCodeContext.Provider>
    </UserContext.Provider>
  </ChakraProvider >;
}
