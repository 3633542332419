import React, { useEffect, useState, useContext } from 'react';

import { useAuthState, useSignInWithGoogle } from 'react-firebase-hooks/auth'
import { query, getDocs, collection, where, setDoc, doc, getDoc } from "firebase/firestore";
import { GoogleAuthProvider, signInWithEmailAndPassword } from "firebase/auth"
import { connectAuthEmulator, getAuth, signInWithPopup } from "firebase/auth"
import { logEvent, setUserId } from 'firebase/analytics';

import { app, auth, db, analytics } from '../firebase'

import {
    Box,
    Flex,
    Button,
    Image,
    Spinner,
    VStack,
    Modal,
    ModalHeader,
    ModalBody,
    ModalContent,
    ModalFooter,
    Input,
    InputGroup,
    InputLeftAddon,
    SimpleGrid,
    useDisclosure,
    Divider,
    FormControl,
    FormHelperText,
    Text,
    useToast
} from "@chakra-ui/react"
import { useNavigate, generatePath } from 'react-router-dom';

// User Imports
import { HideCenterLogo } from '../App';
import MainLogo from '../components/MainLogo';

export const SignInPage = () => {
    const [user] = useAuthState(auth);
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();
    const toast = useToast();

    const { hideCenterLogoState, setHideCenterLogoState } = useContext(HideCenterLogo);
    useEffect(() => {
        // Always make the very top center logo hidden by default
        setHideCenterLogoState(true);
    }, [setHideCenterLogoState])

    // Email modal
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const openEmailModal = async () => {
        onOpen();
    }

    function wait(timeout: number) {
        return new Promise(resolve => {
            setTimeout(resolve, timeout);
        });
    }

    const signInWithEmail = async () => {
        setIsLoading(true);

        try {
            const auth = getAuth();
            const result = await signInWithEmailAndPassword(auth, email, password);
            // Signed in
            const user = result.user;
            
            // Handle analytics
            setUserId(analytics, user.uid);
            logEvent(analytics, 'login', {
                method: "Email and Password"
            })

            const collectionRef = collection(db, 'users');
            const userDocRef = doc(collectionRef, user?.uid, 'private', 'userInfo');
            const userDoc = await getDoc(userDocRef);
            const userDocData = userDoc.exists() ? userDoc.data() : null;
            if (!userDocData) {
                // If a user doesn't have a userDocData, then they are likely signing in for the first time,
                // so log a "sign_up" event
                logEvent(analytics, 'sign_up', {
                    method: "Email and Password"
                })
                
                await setDoc(userDocRef, {
                    uid: user.uid,
                    name: user.displayName,
                    authProvider: "email",
                    email: user.email,
                    setupComplete: false,
                });

                navigate("/profile")
            }
            else {
                if (userDocData && !userDocData.setupComplete) {
                    navigate("/profile")
                }
                else {
                    navigate("/scan")
                }
            }
        } catch (error: any) {
            await wait(3000);
            toast({
                title: "Login Failed",
                description: error.code.toString().replace("auth/", '').split('-').join(' '),
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }

        setIsLoading(false);
    }

    const signInWithGoogle = async () => {
        setIsLoading(true);

        try {
            const provider = new GoogleAuthProvider();
            const result = await signInWithPopup(auth, provider);

            // Signed In
            const user = result.user;

            // Handle analytics
            setUserId(analytics, user.uid);
            logEvent(analytics, 'login', {
                method: "Google"
            })

            const collectionRef = collection(db, 'users');
            const userDocRef = doc(collectionRef, user?.uid, 'private', 'userInfo');
            const userDoc = await getDoc(userDocRef);
            const userDocData = userDoc.exists() ? userDoc.data() : null;
            if (!userDocData) {
                // If a user doesn't have a userDocData, then they are likely signing in for the first time,
                // so log a "sign_up" event
                logEvent(analytics, 'sign_up', {
                    method: "Google"
                })

                await setDoc(userDocRef, {
                    uid: user.uid,
                    name: user.displayName,
                    authProvider: "google",
                    email: user.email,
                    setupComplete: false,
                });

                navigate("/profile")
            }
            else {
                if (userDocData && !userDocData.setupComplete) {
                    navigate("/profile")
                }
                else {
                    navigate("/scan")
                }
            }

        } catch (err: any) {
            await wait(3000);
            toast({
                title: "Login Failed",
                description: err.code.toString().replace("auth/", '').split('-').join(' '),
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }

        setIsLoading(false);
    }

    const createNewAccount = () => {
        setIsLoading(true);

        navigate("/createAccount")

        setIsLoading(false);
    }

    const isValidEmail = (value: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(value);
    }

    const isValidPassword = (value: string) => {
        const MIN_PASSWORD_LENGTH = 8;

        return value.length >= MIN_PASSWORD_LENGTH
    }

    return (
        <Flex width="100%" height="50vh" flexDirection="column">
            <Modal closeOnOverlayClick={true} isOpen={isOpen} onClose={onClose}>
                <ModalContent>
                    <ModalHeader>{"Sign In with Email"}</ModalHeader>
                    <div>
                        <ModalBody pb={6}>
                            <SimpleGrid columns={1} spacing={5}>
                                <FormControl id="email" isInvalid={!isValidEmail(email)}>
                                    <InputGroup size='lg'>
                                        <InputLeftAddon>
                                            <Text>Email</Text>
                                        </InputLeftAddon>
                                        <Input
                                            type="email"
                                            placeholder='your@email.com'
                                            value={email}
                                            onChange={(event) => setEmail(event.target.value)}
                                            isRequired
                                        />
                                    </InputGroup>
                                </FormControl>
                                <FormControl id="password" isInvalid={!isValidPassword(password)}>
                                    <InputGroup size='lg'>
                                        <InputLeftAddon>
                                            <Text>Password</Text>
                                        </InputLeftAddon>
                                        <Input
                                            type="password"
                                            placeholder=''
                                            value={password}
                                            onChange={(event) => setPassword(event.target.value)}
                                            isRequired
                                        />
                                    </InputGroup>
                                </FormControl>
                                <Divider />
                                <Button
                                    type='submit'
                                    variant='solid'
                                    colorScheme='green'
                                    isDisabled={!isValidEmail(email) || !isValidPassword(password)}
                                    onClick={signInWithEmail}
                                    isLoading={isLoading}
                                >
                                    <div>Log In</div>
                                </Button>
                            </SimpleGrid>
                        </ModalBody>
                    </div>
                </ModalContent>
            </Modal>
            <Box flex="1" display="flex" justifyContent="center">
                <VStack>
                    <MainLogo maxH={"150px"}/>
                </VStack>
            </Box>
            {isLoading ? <Spinner /> :
                <>
                    <VStack flex="1">
                        <Button onClick={openEmailModal}>Sign In with Email</Button>
                        <Button onClick={signInWithGoogle}>Sign In with Google</Button>
                        <Divider />
                        <Button onClick={createNewAccount} colorScheme='green'>Create New Account</Button>
                    </VStack>
                </>
            }
        </Flex>
    )
}
