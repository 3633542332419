import { Box, Card, CardBody, CardHeader, Heading } from "@chakra-ui/react";

interface AdminPanelCardProps {
    // Title of the card
    title: string;

    // What is contained within this card
    children?: any
}

// Common design for all the cards on the Admin Panel page
// @param title What to display as the title of this card
// @param children What to display in the card
const AdminPanelCard = ({ title, children }: AdminPanelCardProps) => {
    return (
        <>
            <Card>
                <CardHeader>
                    <Heading size='lg'>{title}</Heading>
                </CardHeader>
                <CardBody>
                    <Box maxW={'sm'} alignItems="center" justifyContent="center" fontSize="xl" >
                        {children}
                    </Box>
                </CardBody>
            </Card>
        </>
    )
}

export default AdminPanelCard;
