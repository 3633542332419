import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getAnalytics } from 'firebase/analytics'
import { getAuth } from "firebase/auth"
import { getStorage} from "firebase/storage"

export const app = initializeApp({
  apiKey: "AIzaSyCp3oT2BUuU9nJo_11cwR1V6hjbYnP532E",
  authDomain: "connect.hhcyasingles.org",
  projectId: "speed-dating-20735",
  storageBucket: "speed-dating-20735.appspot.com",
  messagingSenderId: "619758691159",
  appId: "1:619758691159:web:302697bf7a17a9045dd412",
  measurementId: "G-6RF0PWNL9E"
})

export const db = getFirestore(app);
export const auth = getAuth(app);
export const analytics = getAnalytics(app);
export const storage = getStorage();
