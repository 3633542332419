import React, { useContext, useEffect, useState } from 'react';

import { UserContext, UserQrCodeContext } from '../App';

import { useNavigate } from 'react-router-dom';

import { useAuthState } from 'react-firebase-hooks/auth'
import { useDocumentDataOnce, useCollectionData } from 'react-firebase-hooks/firestore';

import { query, getDocs, collection, where, setDoc, doc, getDoc, serverTimestamp, orderBy, limit, updateDoc } from "firebase/firestore";
import { logEvent } from 'firebase/analytics';
import { app, auth, db, analytics } from '../firebase'

import ReactCardFlip from 'react-card-flip';

import '../customStyles.css';

import {
    Text,
    VStack,
    Button,
    Box,
    useToast,
    Spinner,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    IconButton,
    SimpleGrid,
    HStack,
    Flex,
} from "@chakra-ui/react"
import { ImCheckmark } from 'react-icons/im';
import { ImCross } from 'react-icons/im';

import Html5QrcodePlugin from "../components/Html5QrcodePlugin";
import HHQRCode from "../components/HHQRCode";
import { Html5QrcodeScanType } from 'html5-qrcode';
import { initializeApp } from 'firebase/app';

// User Imports
import { wait } from '../util/wait';
import { TEST_QR_CODE_MAGIC_TEXT } from '../util/tutorial';
import { HideCenterLogo } from '../App';

export const ScannerPage = () => {
    const [user, userLoading] = useAuthState(auth);
    const [showQRCode, setShowQRCode] = useState<boolean>(false)

    const { hideCenterLogoState, setHideCenterLogoState } = useContext(HideCenterLogo);
    useEffect(() => {
        // Always make the very top center logo hidden by default
        setHideCenterLogoState(false);
    }, [setHideCenterLogoState])

    const usersCollectionRef = collection(db, 'users');

    // Current user document
    const currentUserDocRef = user ? doc(usersCollectionRef, user?.uid, 'private', 'userInfo') : undefined;

    // Current user info
    const { currentUserData, currentUserLoading, currentUserError } = useContext(UserContext);
    const { currentUserQrCode, QR_CODE_PREFIX} = useContext(UserQrCodeContext);
    const [readyToScan, setReadyToScan] = useState<boolean>(false);

    // Interaction Modal
    const { isOpen: isInteractionOpen, onOpen: onInteractionOpen, onClose: onInteractionClose } = useDisclosure();

    const [processingInteraction, setProcessingInteraction] = useState<boolean>(false);
    // monitor for interactions sent to me and that I haven't responded to yet
    const [interactionsQuery, setInteractionsQuery] = useState<any>(null);
    const interactionsCollection = collection(db, 'interactions');

    useEffect(() => {
        // Set the state values based on the loaded document
        const interactionsCollection = collection(db, 'interactions'); // prevents useEffect from running too much
        if (currentUserData) {
            setReadyToScan(true);
            setInteractionsQuery(query(interactionsCollection, where("to", "==", currentUserData['uid']), where("like", "==", ""), limit(1), orderBy("timestamp")))
        }
    }, [currentUserData]);

    const [pendingInteractionsValue, pendingInteractionsLoading, pendingInteractionsError] =
        useCollectionData(interactionsQuery);
    const [nextInteraction, setNextInteraction] = useState<any>(undefined)

    useEffect(() => {
        // Set the state values based on the query
        if (pendingInteractionsValue && pendingInteractionsValue?.length > 0) {
            setNextInteraction(pendingInteractionsValue[0]);

            // prevents the analytics log from being called twice for the same interaction,
            // since the pendingInteractionsValue actually updates twice on a scan;
            // once for all the values, and then again with the server timestamp
            if (!isInteractionOpen) { 
                onInteractionOpen();
                logEvent(analytics, 'interaction_begin', {
                    with: [pendingInteractionsValue[0].from, currentUserData['uid']].sort((a, b) => a.localeCompare(b))
                });
            }
        }
        else {
            setNextInteraction(undefined);
        }
    }, [pendingInteractionsValue]);

    const navigate = useNavigate();
    const toast = useToast();

    const [processingScan, setProcessingScan] = useState<boolean>(false);

    // Test Confirmation Modal
    const TEST_BUTTON_WAIT_TIMEOUT_MS = 5000; // how long before the buttons become active on the test modal screen

    const { isOpen: isTestScanResultOpen, onOpen: onTestScanResultOpen, onClose: onTestScanResultClose } = useDisclosure();
    const [isTestScanComplete, setIsTestScanComplete] = useState<boolean>(false);
    const [areTestButtonsActive, setAreTestButtonsActive] = useState<boolean>(false);

    useEffect(() => {
        if (isTestScanResultOpen) {
            wait(TEST_BUTTON_WAIT_TIMEOUT_MS).then(() => {
                setAreTestButtonsActive(true);
            })
        }
    }, [isTestScanResultOpen])

    const handleOnTestConfirm = async () => {
        const TEST_COMPLETE_TIMEOUT_MS = 3000;

        setIsTestScanComplete(true);

        // User activity in this tutorial is complete, log the event
        logEvent(analytics, 'tutorial_complete');

        // Show the completion text for TEST_COMPLETE_TIMEOUT_MS milliseconds
        await wait(TEST_COMPLETE_TIMEOUT_MS);

        onTestScanResultClose();
        setIsTestScanComplete(false);
        setAreTestButtonsActive(false);
    }

    const onNewScanResult = async (decodedText: string, decodedResult: string) => {
        const ERROR_TIMEOUT_MS = 5000;

        console.log(decodedText);
        setProcessingScan(true);

        logEvent(analytics, 'qr_scan', {
            decodedText: decodedText
        });

        // Remove the url from the QR Code to get only the UID
        let scannedUID = decodedText
        if (decodedText.includes(QR_CODE_PREFIX)) {
            scannedUID = decodedText.replace(QR_CODE_PREFIX, "");
        }

        console.log(scannedUID);

        try {
            const collectionRef = collection(db, 'users');
            const userDocRef = doc(collectionRef, scannedUID);
            const userDoc = await getDoc(userDocRef);

            console.log(userDocRef);
            console.log(userDoc);
            console.log(userDoc.exists());

            console.log("Current User Info: ");
            console.log(user);

            // If QR code matches the test qr code text, open the tutorial
            if (scannedUID === TEST_QR_CODE_MAGIC_TEXT) {
                // Begin tutorial
                logEvent(analytics, 'tutorial_begin');

                setAreTestButtonsActive(false)
                onTestScanResultOpen();

                // Record that this user has scanned the test qr code
                if (currentUserDocRef && 
                    (!currentUserData.hasOwnProperty('scannedTestQRCode') || currentUserData['scannedTestQRCode'] === false))
                {
                    await updateDoc(currentUserDocRef, {
                        scannedTestQRCode: true
                    });
                }
            }
            else if (userDoc.exists()) {
                console.log("Cool, that user exists!!");
                console.log(userDoc.data().name);

                // This was a valid QR code!
                if (currentUserData) {

                    if (userDoc.data().uid != currentUserData['uid']) {
                        // Setup an interaction for the other user
                        const interactionOtherDocRef = doc(interactionsCollection);
                        await setDoc(interactionOtherDocRef, {
                            id: interactionOtherDocRef.id,
                            timestamp: serverTimestamp(),
                            from: userDoc.data().uid,
                            fromName: userDoc.data().name,
                            to: currentUserData['uid'],
                            toName: currentUserData['name'],
                            like: ''
                        });

                        // Setup an interaction for this user
                        const interactionUserDocRef = doc(interactionsCollection);
                        await setDoc(interactionUserDocRef, {
                            id: interactionUserDocRef.id,
                            timestamp: serverTimestamp(),
                            from: currentUserData['uid'],
                            fromName: currentUserData['name'],
                            to: userDoc.data().uid,
                            toName: userDoc.data().name,
                            like: ''
                        });
                    }
                    else {
                        toast({
                            title: "Error",
                            description: "You scanned your own QR code!!",
                            status: "error",
                            duration: ERROR_TIMEOUT_MS,
                            isClosable: true
                        });
                    }
                }
                else {
                    toast({
                        title: "Error",
                        description: "Current user is not defined!!",
                        status: "error",
                        duration: ERROR_TIMEOUT_MS,
                        isClosable: true
                    });
                }
            }
            else {
                toast({
                    title: "User Does Not Exist",
                    description: "Are you sure that was another user's QR code? Scanned text: " + decodedText,
                    status: "error",
                    duration: ERROR_TIMEOUT_MS,
                    isClosable: true
                });
                await wait(ERROR_TIMEOUT_MS);
            }
        }
        catch (error) {
            toast({
                title: "Invalid QR Code",
                description: "Are you sure that was another user's QR code? Scanned text: " + decodedText,
                status: "error",
                duration: ERROR_TIMEOUT_MS,
                isClosable: true
            });
            await wait(ERROR_TIMEOUT_MS);
        }

        setProcessingScan(false);
    }

    const handleClick = () => {
        setShowQRCode(!showQRCode);
    }

    const handleOnLike = async () => {
        setProcessingInteraction(true);

        try {
            const interactionDocRef = doc(interactionsCollection, nextInteraction.id);
            const interactingWithID = nextInteraction.from;
            await updateDoc(interactionDocRef, {
                like: "yes",
                timestamp: serverTimestamp()
            })

            logEvent(analytics, 'interaction_complete', {
                like: "yes",
                with: [interactingWithID, currentUserData['uid']].sort((a, b) => a.localeCompare(b))
            });
        }
        catch (err: any) {
            console.error(err);
            toast({
                title: "Something went wrong!",
                description: err,
                status: "error",
                duration: 6000,
                isClosable: true
            });
        }

        onInteractionClose()
        setProcessingInteraction(false);
    }

    const handleOnDislike = async () => {
        setProcessingInteraction(true);

        try {
            const interactionDocRef = doc(interactionsCollection, nextInteraction.id);
            const interactingWithID = nextInteraction.from;
            await updateDoc(interactionDocRef, {
                like: "no",
                timestamp: serverTimestamp()
            })

            logEvent(analytics, 'interaction_complete', {
                like: "no",
                with: [interactingWithID, currentUserData['uid']].sort((a, b) => a.localeCompare(b))
            });
        }
        catch (err: any) {
            console.error(err);
            toast({
                title: "Something went wrong!",
                description: err,
                status: "error",
                duration: 6000,
                isClosable: true
            });
        }

        onInteractionClose();
        setProcessingInteraction(false);

    }

    return (
        <>
            <Modal closeOnOverlayClick={false} isOpen={isTestScanResultOpen} onClose={onTestScanResultClose}>
                <ModalOverlay
                    bg='#48BB788F'
                    backdropFilter='auto'
                />
                <ModalContent>
                    {isTestScanComplete ?
                        <div>
                            <ModalHeader>Perfect!</ModalHeader>
                        </div>
                        :
                        <div>
                            <ModalHeader>You're All Set!</ModalHeader>
                            <ModalBody pb={6}>
                                <Text>Your device is compatible and ready to participate!</Text>
                                <br />
                                <Text>Normally, now is when we'd ask you if you'd like to get to know the person
                                    across from you more. You'd respond with a Blue Check for Yes or a Red X for No!</Text>
                                <br />
                                <Text>Give it a try!</Text>
                            </ModalBody>
                            <ModalFooter>
                                <SimpleGrid columns={2} spacing={10}>
                                    <Flex>
                                        <IconButton
                                            colorScheme='blue'
                                            width='200px'
                                            aria-label='Like'
                                            size='lg'
                                            icon={<ImCheckmark />}
                                            onClick={handleOnTestConfirm}
                                            isDisabled={!areTestButtonsActive}
                                        />
                                    </Flex>
                                    <Flex>
                                        <IconButton
                                            colorScheme='red'
                                            width='200px'
                                            aria-label='Dislike'
                                            size='lg'
                                            icon={<ImCross />}
                                            onClick={handleOnTestConfirm}
                                            isDisabled={!areTestButtonsActive}
                                        />
                                    </Flex>
                                </SimpleGrid>
                            </ModalFooter>
                        </div>
                    }
                </ModalContent>
            </Modal>
            <Modal closeOnOverlayClick={false} isOpen={isInteractionOpen} onClose={onInteractionClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{nextInteraction ? nextInteraction?.fromName : "Processing..."}</ModalHeader>
                    <ModalBody pb={6}>
                        {nextInteraction ? <>Are you interested in getting to know {nextInteraction?.fromName} more?</> : <></>}
                    </ModalBody>

                    <ModalFooter>
                        <SimpleGrid columns={2} spacing={10}>
                            <Flex>
                                <IconButton
                                    colorScheme='blue'
                                    width='200px'
                                    aria-label='Like'
                                    size='lg'
                                    icon={<ImCheckmark />}
                                    isLoading={processingInteraction}
                                    onClick={handleOnLike}
                                />
                            </Flex>
                            <Flex>
                                <IconButton
                                    colorScheme='red'
                                    width='200px'
                                    aria-label='Dislike'
                                    size='lg'
                                    icon={<ImCross />}
                                    isLoading={processingInteraction}
                                    onClick={handleOnDislike} />
                            </Flex>
                        </SimpleGrid>
                    </ModalFooter>
                </ModalContent>
            </Modal >
            <Box>
                <ReactCardFlip isFlipped={showQRCode} flipDirection='horizontal'>
                    <VStack spacing={8}>
                        <Button colorScheme={'blue'} onClick={handleClick}>Show Your QR Code</Button>
                        {nextInteraction || !readyToScan || processingScan || showQRCode || isTestScanResultOpen ?
                            <Spinner
                                thickness='4px'
                                size='xl'
                                visibility={showQRCode ? "hidden" : "visible"}
                            />
                            :
                            <div style={{ margin: '5px' }}>
                                <Html5QrcodePlugin
                                    fps={20}
                                    qrbox={250}
                                    disableFlip={false}
                                    qrCodeSuccessCallback={onNewScanResult}
                                    supportedScanTypes={[Html5QrcodeScanType.SCAN_TYPE_CAMERA]}
                                />
                            </div>
                        }
                    </VStack>
                    <VStack spacing={8}>
                        <Button colorScheme={'blue'} onClick={handleClick}>Show Scanner</Button>
                        <Box backgroundColor={'white'} visibility={showQRCode ? "visible" : "hidden"}>
                            <HHQRCode qrValue={currentUserQrCode} size={250}/>
                        </Box>
                    </VStack>
                </ReactCardFlip>
            </Box>
        </>
    )
}