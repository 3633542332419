import {
    Image,
    useToast,
    useDisclosure
} from "@chakra-ui/react"

import { ScaleFade } from '@chakra-ui/react'

import React, { useEffect, useState, useContext } from 'react';

import { wait } from "../util/wait";
import { MainLogoURLContext } from "../App";

interface MainLogoProps {
    // How tall to make the image?
    maxH?: any;

    // Should the logo animate on its way in?
    enableAnimation?: boolean
}

// Common design for all the uses of the Main Logo in the app
// @param maxH max height of the logo
// @param enableAnimation Whether or not the logo should fade in or not
const MainLogo = ({maxH = undefined, enableAnimation = true}: MainLogoProps) => {
    // Enables the animation when the logo is loaded
    const { isOpen: isLogoOpen, onToggle: onLogoToggle } = useDisclosure()

    // Grab the mainLogoURL from App.tsx's MainLogoURLContext
    const { mainLogoURL } = useContext(MainLogoURLContext);

    useEffect(() => {
        // Function to handle the animation
        const handleAnimation = () => {
            const TRANSITION_DELAY_MS = 500; // how long to wait before the logo animation displays

            if (!isLogoOpen) {
                wait(TRANSITION_DELAY_MS).then(() => {
                    onLogoToggle();
                })
            }
        }

        handleAnimation();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mainLogoURL])

    return (
        <>
            {enableAnimation ? // Only animate if `enableAnimation` is true
                <ScaleFade in={isLogoOpen} initialScale={0.25} hidden={!mainLogoURL}>
                    <Image src={mainLogoURL} maxH={maxH} />
                </ScaleFade >
            :
                <Image src={mainLogoURL} maxH={maxH} hidden={!mainLogoURL}/>
            }
        </>
    )
}

export default MainLogo;
