
// Return a new datetime in the local timezone
//
// @param date_in utc time
// @return date in local time
export function convertUTCToLocal(date_in: Date | undefined) : string {
    if (date_in) {
        const localDate = (new Date(date_in.getTime() - date_in.getTimezoneOffset() * 60000).toISOString()).slice(0, -8);
        return localDate;
    }

    return "";
}


// Return a new Date object with UTC time with timezone information
//
// @param date_str local time
// @return Date() object with full timezone info
export function localToUTCDatetime(date_str: string): Date {
    const fakeUtcTime = new Date(`${date_str}Z`);
    const date = new Date(fakeUtcTime.getTime() + fakeUtcTime.getTimezoneOffset() * 60000);

    return date;
}