import { Html5QrcodeScanner, Html5Qrcode, Html5QrcodeScannerState } from 'html5-qrcode';
import { useEffect, useState } from 'react';

import {
    Box,
    Button,
    useToast,
    Spinner,
    Text,
} from "@chakra-ui/react"

const qrcodeRegionId = "html5qr-code-full-region";

let html5QrCode;
// Creates the configuration object for Html5QrcodeScanner.
const createConfig = (props) => {
    let config = {};
    if (props.fps) {
        config.fps = props.fps;
    }
    if (props.qrbox) {
        config.qrbox = props.qrbox;
    }
    if (props.aspectRatio) {
        config.aspectRatio = props.aspectRatio;
    }
    if (props.disableFlip !== undefined) {
        config.disableFlip = props.disableFlip;
    }
    if (props.supportedScanTypes) {
        config.supportedScanTypes = props.supportedScanTypes;
    }
    console.log(config)
    return config;
};

const Html5QrcodePlugin = (props) => {
    const config = createConfig(props);

    const toast = useToast();
    const [scannerStarted, setScannerStarted] = useState(false);
    const [scannerLoading, setScannerLoading] = useState(true);

    useEffect(() => {
        html5QrCode = new Html5Qrcode(qrcodeRegionId);

        // Start the scanner
        console.log(config);
        html5QrCode.start({ facingMode: "environment" }, config, props.qrCodeSuccessCallback).then((res) => {
            setScannerLoading(false);
            setScannerStarted(true);
        })
            .catch((err) => {
                console.error(err)
                toast({
                    title: "Error Starting Scanner",
                    description: "Did you grant your browser Camera Permissions? Otherwise, your browser may not be supported :(",
                    status: "error",
                    isClosable: false,
                });

                setScannerLoading(false);
            });

        // Cleanup function when component will unmount
        return () => {
            if (html5QrCode.getState() == Html5QrcodeScannerState.SCANNING) {
                html5QrCode.stop().then((res) => {
                    html5QrCode.clear();
                })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        }
    }, []);

    return (
        <>
            {/* TODO: How to do this without the hidden text??? */}
            <Box minW={"100%"}>
                <div id={qrcodeRegionId} margin="5px" background-color="#ff0000" />
                <Text visibility="hidden">Some really fancy text goes here to force the video to span the entire width of the page</Text>
            </Box>
            {
                scannerLoading ?
                    <>
                        <Spinner
                            thickness='4px'
                            size='xl'
                        />
                    </> :
                    <>
                        {!scannerStarted ?
                            <>
                                <Text>Scanner failed to start.</Text>
                                <Text>Check your browser's Camera Permissions.</Text>
                            </> :
                            <>
                            </>
                        }
                    </>
            }
        </>
    );
};

export default Html5QrcodePlugin;